.card-use-box {
	text-align: center;

	.crd-bx-img {
		position: relative;
		width: 100px;
		margin: auto;

		.active {
			width: 15px;
			height: 15px;
			background: $success;
			border-radius: 50%;
			left: 6rem;
			position: absolute;
			bottom: 0.4rem;

			&.deactive {
				background: $danger;
			}
		}

		img {
			border: 7px solid rgba(62, 95, 206, 0.08);
		}
	}
}

.card__info {
	display: flex;
	align-items: center;
	display: flex;
	align-items: center;
	justify-content: center;

	li {
		.card__info__stats {
			display: block;
			color: var(--primary);
			font-size: 1.2rem;
			font-weight: 500;
			padding: 0 40px;

			@include respond ('laptop') {
				padding: 0 25px;
			}
		}
	}
}

.post-pos {
	text-align: center;
	margin: 18px;

	.card__info__stats {
		color: var(--primary);

	}

	li {
		margin-bottom: 10px;
	}
}

.user-m-tabe {
	.nav-link {
		padding: 3px 8px;
	}

	margin-top: 0px;
}

.personal-info {
	li {
		font-size: 0.9rem;
		font-weight: 500;
		padding: 12px 20px;

		&:hover {
			background: var(--rgba-primary-1);
		}
	}
}

.action-button {
	.btn.btn-icon-xxs {
		padding: 0.075rem 0.2375rem;

		i {
			transform: scale(0.8);
		}
	}
}

.all_user {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 10px 20px;
	background: var(--rgba-primary-1);
}

.all_user1 {
	display: flex;
	justify-content: space-between;
	padding: 9px 30px;

	.member {
		margin-right: 4rem;

		.heading {
			padding: 0 10px;
		}

	}
}

.right-check {
	.custom-checkbox {
		margin-right: 5rem;

		@include respond ('phone') {
			margin-right: 1rem;
		}
	}
}

.manage {
	padding: 0 30px;
	margin-top: -10px;
}

.role-tble {
	tbody {
		tr {
			td {
				&:not(:first-child) {
					.custom-checkbox {
						display: flex;
						justify-content: end;
					}
				}
			}
		}
	}

	&.table {
		tbody tr td {
			padding: 0.4375rem 0.625rem;
			border: 0;
		}

		thead tr th {
			padding: 0.4375rem 0.625rem;
		}
	}
}

// User Profile

.c-details {
	ul {
		li {
			border-bottom: 1px solid $border;
			padding: 10px 0;

			p {
				color: black;
				margin: 0;
			}

		}
	}
}

.c-social {
	display: flex;
	margin-top: 15px;

	li {
		a {
			padding: 5px 10px;
			margin: 0 7px;
			border-radius: $radius-xs;

			&:first-child {
				margin-left: 0;
			}
		}
	}
}

.c-busiess {
	.avatar {
		height: 60px;
		width: 60px;
		margin-right: 10px;
	}
}

.c-card-details {
	ul {
		li {
			display: flex;
			padding: 6px 0px;

			h6 {
				margin-left: 5px;
				margin-bottom: 0;
				font-size: 13px;
			}
		}
	}
}

.c-work {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.c-task {
		p {
			margin-bottom: 0;
			color: white;
			display: flex;
			align-items: center;
		}

		span {
			color: white;
		}

		padding: 7px 20px;
		border-radius: $radius-xs;
		margin: 0 6px;

		@include respond ('phone') {
			padding: 7px 12px;
		}

		&:first-child {
			margin-left: 0;
		}
	}
}

.c-primium {
	li {
		display: flex;
		justify-content: space-between;
		padding: 6px 0px;

		h6 {
			margin-bottom: 0;
			font-size: 13px;
		}
	}
}

.c-status {
	i {
		transform: scale(0.65);
		margin-right: 5px;
	}
}

.text-available {
	color: #43d043 !important;
}

.text-disabled {
	color: #9E9E9E !important;
}

.textarea-resize {
	overflow: auto;
	resize: vertical !important;
}

//blog-data
.blog-card {
	h4 {
		font-size: 14px;

	}

	img {
		margin-bottom: 10px;

		@include respond ('phone') {
			width: 45px;
		}
	}

	@include transitionMedium;

	&:hover {
		transform: translate(0, -10px);
	}
}

@include respond ('phone') {
	.c-busiess {
		margin-bottom: 10px;
	}
}

.blog-title {
	position: relative;

	&:before {
		content: "";
		height: 4px;
		width: 60px;
		background-color: var(--primary);
		position: absolute;
		bottom: -5px;
		left: 0;
		border-radius: 10px;
	}

	&:after {
		content: "";
		height: 5px;
		width: 5px;
		background-color: var(--primary);
		position: absolute;
		bottom: -5px;
		left: 65px;
		border-radius: 50px;
	}
}

.blog-img {
	position: relative;

	.blk-img {
		width: 100%;
		height: 575px;
		object-fit: cover;
		border-radius: $radius;

		@include respond ('phone') {
			height: 350px;
		}
	}

	.blk-img2 {
		width: 100%;
		height: 400px;
		object-fit: cover;
		border-radius: $radius;

		@include respond ('phone') {
			height: 300px;
		}
	}

	.blog-text {
		position: absolute;
		bottom: 0;
		padding: 20px 15px;

		h2 {
			font-size: 30px;
			line-height: 1.1;
			color: white;
			font-weight: 600;

			@include respond ('tab-land') {
				font-size: 20px;
			}
		}

		span {
			color: white;
		}
	}

	&.style-1 {
		.blk-img {
			height: 275px;
		}

		.blog-text {
			h2 {
				font-size: 16px;
				font-weight: 500;
			}
		}
	}
}

.blog-post {
	.post-1 {
		display: flex;
		align-items: center;
		margin-bottom: 21px;

		.custome-avatar {
			height: 90px;
			width: 90px;
			border-radius: 50%;

			@include respond ('tab-land') {
				height: 70px;
				width: 70px;
			}
		}

		.post-data {
			h4 {
				font-size: 15px;
				margin-top: 5px;

				@include respond ('phone') {
					font-size: 14px;
				}
			}

			span {
				font-size: 12px;
			}
		}

	}

	@include respond ('tab-land') {
		margin-top: 10px;
	}
}

.small-post {
	height: 1.275rem !important;
	width: 1.275rem !important;
}

.seconday-post {
	margin-top: 25px;
	display: flex;

	@include respond ('phone') {
		flex-flow: wrap;
	}

	img {
		width: 160px;
		height: 130px;
		border-radius: $radius;
		margin-right: 18px;
		object-fit: cover;

		@include respond ('phone') {
			width: 100%;
			height: 200px;
		}
	}

	h4 {
		font-size: 13px;
		margin-top: 6px;
	}

	span {
		font-size: 12px;
	}

	.post-data {
		@include respond ('phone') {
			margin-top: 15px;
		}
	}
}

.third-post {
	display: flex;
	align-items: center;
	margin-bottom: 21px;

	@include respond ('tab-land') {
		flex-flow: wrap;
	}

	img {
		width: 300px;
		height: 180px;
		border-radius: 0.375rem;
		margin-right: 18px;
		object-fit: cover;

		@include respond ('tab-land') {
			width: 100%;
			height: 220px;
		}
	}

	h4 {
		font-size: 15px;
		margin-top: 6px;
	}

	span {
		font-size: 12px;
	}

	&.style-1 {
		margin-top: 20px;

		img {
			width: 90px;
			height: 80px;
		}

		h4 {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&.style-2 {
		margin-bottom: 0;
		border-bottom: 1px solid $border-color;

		.av-post {
			height: 40px;
			width: 40px;
		}

		.post-data {
			margin-bottom: 14px;
		}
	}

	.post-data {
		@include respond ('tab-land') {
			margin-top: 15px;
		}
	}

}

.notifiy {
	background-color: var(--rgba-primary-1);
	position: relative;
	margin-top: 40px;

	.icon-box {
		position: absolute;
		top: -12px;
		left: 46%;
	}

	.notify-data {
		text-align: center;
		padding: 20px 0;
		padding-bottom: 0;

		span {
			font-size: 12px;
		}
	}
}

.auto-break-word {
	overflow-wrap: break-word !important;
	white-space: pre-wrap !important;
}

.auto-break-character {
	overflow-wrap: anywhere !important;
	white-space: pre-wrap !important;
}

.logo-vendor {
	width: 120px !important;
	height: 120px !important;
}

.sub-users-table {
	&.table {
		tbody tr td {
			font-size: 13px !important
		}

		thead tr th {
			font-size: 13px !important;
			color: #6e70ae;
		}
	}
}